import React from 'react';
import anime from 'animejs';
import './HallDecorationComp.css';
import PhotoGallery from 'react-photo-gallery';
import { Row, Col } from 'reactstrap';
import Lightbox from 'react-images';
import AWS from 'aws-sdk';

var PHOTO_SET_DESKTOP = [];
var PHOTO_SET_MOBILE = [];
var LIGHTBOX_PHOTO_SET = [];

var s3 = new AWS.S3();
var params = {
  Bucket: 'laverose-pictures', /* required */
  Prefix: 'Wedding/HallDecoration/'  // Can be your folder name
};

var getS3Promise = new Promise(function(resolve,reject) {
    s3.listObjectsV2(params, function(err, data) {
      if (err) {console.log(err, err.stack); reject(Error(err));}// an error occurred
      else {  // successful response
        console.log(data);
        console.log('keycount ' + data.KeyCount);
        resolve(data);
      }          
    });
});
getS3Promise.then((data) => {
  console.log('bucketSize ' + data.KeyCount);
    for(let i = 1; i < data.KeyCount; i++) {
      PHOTO_SET_DESKTOP.push({ src:'https://laverose-pictures.s3.amazonaws.com/Wedding/HallDecoration/HallDecoration_' + i + '.jpg', width: 3, height: 4 });
      PHOTO_SET_MOBILE.push({ src:'https://laverose-pictures.s3.amazonaws.com/Wedding/HallDecoration/HallDecoration_' + i + '.jpg', width: 3, height: 4 });
      LIGHTBOX_PHOTO_SET.push({ src:'https://laverose-pictures.s3.amazonaws.com/Wedding/HallDecoration/HallDecoration_' + i + '.jpg', width: 6, height: 10 });
    }
    this.setState({
      photos: PHOTO_SET_DESKTOP
    });
});


export default class HallDecorationComp extends React.Component {

    constructor() {
        super();
        this.state = { 
          currentImage: 0,
          photos: [],
          columns: 1,
          isHidden: true
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
      }
      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true
        });
    
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }
    
      resize() {
        if (window.innerWidth < 768) {
          this.setState({
            photos: PHOTO_SET_MOBILE,
            columns: 1,
            isHidden: false
          })  
        } else {
          this.setState({
            photos: PHOTO_SET_DESKTOP,
            columns: 4,
            isHidden: true
          })  
        }
      }
    
      componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        this.appearanime = anime({
            targets: '.comp-class',
            opacity: 1,
            easing: 'linear'
        });
      }

    render() {
        return (
            <div className={`comp-class halldecoration-comp`}>
                <div className="Gallery">
                    <Row>
                        <Col xs={{ size: 10, offset:1 }}
                            sm={{ size: 10, offset:1 }}
                            lg={{ size: 8, offset:2 }}
                            xl={{ size: 8, offset:2 }}
                            className="text-center-class">
                            <PhotoGallery photos={this.state.photos} columns={this.state.columns} onClick={this.openLightbox} />
                            <Lightbox images={LIGHTBOX_PHOTO_SET}
                                onClose={this.closeLightbox}
                                onClickPrev={this.gotoPrevious}
                                onClickNext={this.gotoNext}
                                currentImage={this.state.currentImage}
                                isOpen={this.state.lightboxIsOpen}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}