import React from 'react';
import './Academy.css';
import anime from 'animejs';


export default class AcademyComp extends React.Component {
    componentDidMount() {
        this.appearanime = anime({
          targets: '.comp-class',
          opacity:1,
          easing: 'linear'
        });
    }
    render() {
        return (
            <div className={`comp-class academy-comp`}>
            </div>
        );
    }
}