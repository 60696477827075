import React from 'react';
import './Contact.css';
import anime from 'animejs';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";

const MapWithAMarker = withGoogleMap(props =>
    <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: 36.349157, lng: 127.398051 }}
    >
        <Marker
            position={{ lat: 36.349157, lng: 127.398051 }}
        />
    </GoogleMap>
);

export default class ContactComp extends React.Component {
    componentDidMount() {
        this.appearanime = anime({
            targets: '.comp-class',
            opacity: 1,
            easing: 'linear'
        });
    }
    render() {
        return (
            <div className={`comp-class contact-comp`}>
                <div className="Gallery">
                    라비앙 로즈 (La Vie En Rose) <br/><br/>
                    대전시 서구 둔산3동 2130번지 2층 라비앙로즈 <br/><br/>
                    T.010-4461-0616<br/><br/>
                    lee_minhye@naver.com<br/><br/>
                            <MapWithAMarker   
                                containerElement={<div className={`google-map-class`} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                    
                </div>
                <div className={`blankspace`}></div>
            </div>
        );
    }
}