import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from './components/NotFound';
import NavbarComp from './components/NavbarComp';
import App from './App';
import AboutComp from './components/about/AboutComp';
// import FlowerComp from './components/flower/FlowerComp';
import HandTideComp from './components/flower/HandTide/HandTideComp';
import FlowerBoxComp from './components/flower/FlowerBox/FlowerBoxComp';
import FlowerBasketComp from './components/flower/FlowerBasket/FlowerBasketComp';
import EventComp from './components/flower/Event/EventComp';
import SubscriptionComp from './components/subscription/SubscriptionComp';
import PreserveFlowerComp from './components/flower/PreserveFlower/PreserveFlowerComp';
// import PlantComp from './components/plant/PlantComp';
import EasternOrchidComp from './components/plant/EasternOrchid/EasternOrchidComp';
import WesternOrchidComp from './components/plant/WesternOrchid/WesternOrchidComp';
import PlanterComp from './components/plant/Planter/PlanterComp';
// import WeddingComp from './components/wedding/WeddingComp';
import BouquetComp from './components/wedding/Bouquet/BouquetComp';
import CarComp from './components/wedding/Car/CarComp';
import HallDecorationComp from './components/wedding/HallDecoration/HallDecorationComp';
import AcademyComp from './components/academy/AcademyComp';
import FloralartComp from './components/floralart/FloralartComp';
import ContactComp from './components/contact/ContactComp';
import Footer from './components/footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
        <Router>
            <div>
            <Route component={NavbarComp} />
            <Switch>
                <Route exact path="/" component={App} />
                <Route exact path="/About" component={AboutComp} />
                <Route exact path="/Subscription" component ={SubscriptionComp}/>
                 {/* Under Flower Dropdown */}
                <Route exact path="/HandTide" component={HandTideComp} />
                <Route exact path="/FlowerBox" component={FlowerBoxComp} />
                <Route exact path="/FlowerBasket" component={FlowerBasketComp} />
                <Route exact path="/PreserveFlower" component={PreserveFlowerComp} />
                <Route exact path="/Event" component={EventComp} />
                {/* Under Plant Dropdown */}
                <Route exact path="/EasternOrchid" component ={EasternOrchidComp}/>
                <Route exact path="/WesternOrchid" component ={WesternOrchidComp}/>
                <Route exact path="/Planter" component ={PlanterComp}/>
                {/* <Route exact path="/Wedding" component={WeddingComp} /> */}
                <Route exact path="/WeddingBouquet" component ={BouquetComp}/>
                <Route exact path="/WeddingCar" component ={CarComp}/>
                <Route exact path="/HallDecoration" component ={HallDecorationComp}/>
                <Route exact path="/Academy" component={AcademyComp} />
                <Route exact path="/Floralart" component={FloralartComp} />
                <Route exact path="/Contact" component={ContactComp} />
                <Route component={NotFound} />
            </Switch>
            <Route component={Footer} />
            </div>
        </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
