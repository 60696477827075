import React from 'react';
import anime from 'animejs';
import './Floralart.css';

export default class FloralartComp extends React.Component {
    componentDidMount() {
        this.appearanime = anime({
          targets: '.comp-class',
          opacity:1,
          easing: 'linear'
        });
    }
    render() {
        return (
            <div className={`comp-class floralart-comp`}>
            </div>
        );
    }
}