import React,{Component} from 'react';
import './SubscriptionComp.css';

export default class SubscriptionComp extends Component {
    componentDidMount() {
    }    
    render() {
        return(
               <div></div>
        );
    }
}