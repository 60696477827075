import React from 'react';
import './About.css';
import anime from 'animejs';
import {
    Row, Col
} from 'reactstrap';

export default class AboutComp extends React.Component {
    componentDidMount() {
        this.appearanime = anime({
          targets: '.comp-class',
          opacity:1,
          easing: 'linear'
        });
    }
    render() {
        return (
            <div className={`comp-class about-comp`}>
                <Row>
                    <Col md={{ size: 6, offset: 5 }} lg={{ size: 6, offset: 5 }} xl={{ size: 6, offset: 5 }}>
                    <p className={`about-description`}>
                    <span className={`about-name`}>디자이너 이민혜</span><br/><br/>
                    2018. 기능경기대회를 위한 데몬스트레이션 초청작가<br/><br/>
                    2018. 53회 전국기능경기대회 화훼장식 은메달<br/><br/>
                    2018. 지방기능경기대회 화훼장식 금메달<br/><br/>
                    2017. 52회 전국기능경기대회 화훼장식 장려상<br/><br/>
                    2017. 지방기능경기대회 화훼장식 금메달<br/><br/>
                    2016. 8th Dott competition SROAS-EFDA prize<br/><br/>
                    2016. 19회 KFDA 화훼장식경기대회 대상 (농림축산식품부장관상)<br/><br/>
                    2016. 대한민국 화훼장식기능경기대회 특별상<br/><br/>
                    2016. 6회 전국화훼장식기능경기대회 장려상<br/><br/>
                    2016. 지방기능경기대회 은메달<br/><br/>
                    2015. 15회 KOREA CUP 디자인경기대회 3위<br/><br/>
                </p>
                    </Col>
                </Row>
            </div>
        );
    }
}