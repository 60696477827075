import React,{Component} from 'react';
import './Footer.css';
import * as FontAwesome from 'react-icons/lib/fa';
import anime from 'animejs';

class Footer extends Component {
    componentDidMount() {
        this.footer = anime({
            targets: '.footer',
            opacity:1,
            easing:'linear'
        });
    }    
    render() {
        return(
                <footer className="footer">
                    <div className="footer-container">
                        <p className={`footer-description`}>대전시 서구 둔산남로 185, 2층 T. 010-4461-0616 / Contact: lee_minhye@naver.com / 상호명: 라비앙 로즈 (Lavieen Rose) / 대표이사: 이민혜 / 사업자번호: 154-29-00593</p>
                        <a href="https://www.facebook.com/La-vie-en_rose-라비앙-로즈-226251398330328"  rel="noopener noreferrer" target="_blank" className="footer-icon-link"><FontAwesome.FaFacebook size={60} className="cursor-pointer fontawesome-icon"/></a>
                        <a href="https://www.instagram.com/lavieenrose.office/"  rel="noopener noreferrer" target="_blank" className="footer-icon-link"><FontAwesome.FaInstagram size={60} className="cursor-pointer fontawesome-icon"/></a>
                    </div>
                </footer>
        );
    }
}
export default Footer;